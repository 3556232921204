<template>
  <component
    :is="component"
    ref="field"
    v-model="computedValue"
    :field="field"
    @action="onAction"
    @change="onChange"
    @load-formatter="$emit('load-formatter', $event)"
  />
</template>

<script>
import { base } from "@/components/Tools/FormHelper/Helper/mixins";
import _ from "lodash";
import {
  fieldDefaults,
  skipFields
} from "@/components/Tools/FormHelper/Helper/constants";
import { typeCheck } from "@/components/Tools/FormHelper/Helper/functions";

export default {
  components: {},
  mixins: [base],
  props: {
    component: {
      type: null,
      default: null
    }
  },
  data() {
    return {};
  },
  computed: {
    values: function () {
      return this.defaultValue;
    },
    computedValue: {
      get: function () {
        // Return if field has no name, e.g. action, alert...
        if (skipFields.includes(this.field.type)) {
          return;
        }
        // Return value
        return _.get(this.values, this.field.name);
      },
      set: function (value) {
        // Return if field has no name, e.g. action, alert...
        if (skipFields.includes(this.field.type)) {
          return;
        }
        // Emit payload containing field name and new value
        this.$emit("input", { name: this.field.name, value: value });
      }
    }
  },
  mounted() {
    this.setDefaultValue();
  },
  methods: {
    setDefaultValue() {
      // Return if field has no name, e.g. action, alert...
      if (skipFields.includes(this.field.type)) {
        return;
      }
      // Get value
      let value = _.get(this.values, this.field.name);
      if (
        value === undefined ||
        _.isEqual(
          value,
          this.field.default ?? fieldDefaults[this.field.type]
        ) ||
        (!_.isEqual(typeCheck(value, this.field.type), value) &&
          !(this.field.type === "condition" && typeof value === "string"))
      ) {
        // Get fallback value
        let fallback = this.field.default ?? fieldDefaults[this.field.type];
        // Emit payload containing field name and new value
        this.$emit("input", { name: this.field.name, value: fallback });
      }
    },
    validate() {
      // On validate, return field's validate return
      return this.$refs.field.validate();
    },
    onAction(payload) {
      // If action was dispatched, emit event payload
      this.$emit("action", payload);
    },
    onChange(payload) {
      // If action was dispatched, emit event payload
      this.$emit("change", payload);
    }
  }
};
</script>
