var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isMounted)?_c('div',{staticClass:"form-helper",class:{ factory: _vm.factory }},[(_vm.config.title)?_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"h5 mb-0 col-12 font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.config.title)+" ")])]):_vm._e(),_c('div',{staticClass:"row row--dense"},_vm._l((_vm.computedForm),function(formField){return _c('div',{key:_vm.factory ? formField.id : formField.name,staticClass:"form-field py-1",class:[
        { 'col-12': formField.type !== 'hidden' && !formField.colClass },
        formField.colClass,
        formField.class,
        { 'py-1': _vm.options.labelStacked },
        { active: _vm.factory && _vm.activeFieldId === formField.id },
        { 'd-flex align-items-center justify-content-left rounded': _vm.factory }
      ]},[_c('FormField',{ref:'field-' + formField.name,refInFor:true,staticClass:"flex-grow-1",attrs:{"component":_vm.formElements[formField.type],"field":formField},on:{"action":_vm.onAction,"change":_vm.onChange,"load-formatter":function($event){return _vm.$emit('load-formatter', $event)}},model:{value:(_vm.computedValues),callback:function ($$v) {_vm.computedValues=$$v},expression:"computedValues"}}),(_vm.factory)?_c('div',[_c('div',{staticClass:"btn text-hover-primary",on:{"click":e => _vm.onClick(formField)}},[_c('i',{staticClass:"fal p-0",class:[
              _vm.activeFieldId === formField.id ? 'fa-pen-slash' : 'fa-pen'
            ]})])]):_vm._e()],1)}),0),(_vm.target)?_c('b-tooltip',{attrs:{"show":true,"target":_vm.target,"noninteractive":"","placement":"top","custom-class":"form-helper-tooltip","boundary":"document"}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("formHelper.value")))]),_vm._v(" "+_vm._s(_vm.variableValue ? _vm.variableValue : _vm.$t("formHelper.noValueFound"))+" ")]):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }