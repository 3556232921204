import {
  getDataOptions,
  getSnippet,
  getValidations,
  highlight,
  restoreCursorPosition,
  saveCursorPosition
} from "@/components/Tools/FormHelper/Helper/functions";
import { copyToClipboard } from "@/components/Tools/helperFunctions";
import {
  customRegex,
  fieldDefaults,
  returnTypeIcons
} from "@/components/Tools/FormHelper/Helper/constants";
import _ from "lodash";
import DataOptions from "@/components/Tools/FormHelper/dataOptions";

export const eagerValidation = {
  inject: ["options"],
  mounted() {
    if (this.options.eagerValidation) {
      this.validate();
    }
  }
};

export const base = {
  inject: ["options", "variablesField", "formFields", "formValue"],
  model: {
    prop: "defaultValue"
  },
  props: {
    // Injected from v-model prop
    defaultValue: {
      type: null,
      default: "",
      required: true
    },
    // Field object with configuration
    field: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      manualError: false
    };
  },
  validations() {
    return {
      value: getValidations(
        this.field.validations ?? {},
        this.enableVariables,
        this.field.type
      )
    };
  },
  computed: {
    value: {
      get: function () {
        // Return inherited value
        return this.defaultValue;
      },
      set: function (value) {
        // Trigger change event firstly, to add old value to payload
        this.onChange(value);
        // Trigger input event with new value
        this.onInput(value);
      }
    },
    hasError: function () {
      return this.$v.$error || this.manualError;
    },
    enableVariables: function () {
      return (
        this.field.enableVariables ||
        (this.field.enableVariables === undefined &&
          this.options.enableVariables)
      );
    },
    // Get correct form validation css class
    validationClass: function () {
      let classText = "";
      if (this.hasError) {
        // If field is dirty and invalid
        classText = "is-invalid";
      } else if (
        this.$v.$dirty &&
        !this.$v.$error &&
        this.$v.value.$model &&
        !this.field.disableValidations
      ) {
        // Else if field is dirty and valid
        classText = "is-valid";
      }
      // Else: if field is not dirty yet, set no class
      return classText;
    },
    // Returns all current errors as messages in array
    validationErrors: function () {
      // Return if field is not dirty yet
      if (!this.$v.value.$dirty) {
        return [];
      }
      let errorMsg = [];
      let errors = this.$v.value;
      // Iterate over $v properties
      Object.keys(errors).forEach(validator => {
        // Continue if property is no validator (starts with "$")
        // or validator is not invalid
        if (validator.startsWith("$") || errors[validator] === true) {
          return;
        }
        let label = this.getSnippet(this.field.label ?? this.field.name);
        // Add validator's error message to array
        errorMsg.push(
          this.$t(
            "formHelper.errors." + validator,
            Object.assign({}, this.$v.value.$params[validator], {
              label: label
            })
          )
        );
      });
      // Return array of validator errors
      return errorMsg;
    },
    isDisabled: function () {
      const { dependsOnValue } = this.field?.disabled || {};

      if (dependsOnValue) {
        const mode = dependsOnValue.mode;
        const values = dependsOnValue.values;
        const isSingleMode = mode === "single";
        const isAllMode = mode === "all";

        // returns all fields that are contained in the specified form fields as well as in the fields of the array dependsOnValue
        const fieldsToCheck = values.filter(dependency =>
          this.formFields.some(formField => dependency.name === formField.name)
        );

        // Retrieve the disabled status depending on whether at least one condition (single mode) or all conditions (all mode) are met
        const isDisabled = isSingleMode
          ? fieldsToCheck.some(field =>
            this.isFieldMatch(field, this.formValue)
          )
          : isAllMode
            ? fieldsToCheck.every(field =>
              this.isFieldMatch(field, this.formValue)
            )
            : false;

        return !!isDisabled;
      } else {
        // Return disabled state
        return !!this.field.disabled;
      }
    }
  },
  methods: {
    // Validate value and return result
    validate() {
      // Trigger vuelidate validation
      this.$v.$touch();
      // Return if vuelidate has errors
      return !this.$v.$anyError;
    },
    // Emit new value by default
    onInput(value) {
      this.$emit("input", value);
      // If custom function call is set
      if (this.field.onInput && typeof this.field.onInput === "function") {
        // Call function
        this.field.onInput(this.value);
      }
    },
    // Emit information about changed field
    onChange(value) {
      const changeEventPayload = {
        name: this.field.name,
        value: value,
        valuePath: "",
        old: this.value
      };
      this.$emit("change", changeEventPayload);
      // Execute onChange function if set
      if (typeof this.field.onChange === "function") {
        this.field.onChange();
      }

      this.checkSetValue(value);
    },
    // Get text as snippet by given prefix
    getSnippet(text) {
      let prefix = this.options.snippetPrefix;
      let snippet = getSnippet(text, prefix);
      // If no snippet is found and field is type json, try to find nested values snippets
      if (snippet === text && this.field.type === "json") {
        // Add nested values prefix by field name + "Values"
        prefix = `${prefix}.${this.field.name}Values`;
        snippet = getSnippet(text, prefix);
      }
      return snippet;
    },
    // Copy value to clipboard
    copyValue() {
      let value =
        typeof this.value === "object"
          ? JSON.stringify(this.value)
          : this.value;
      copyToClipboard(value);
    },
    isFieldMatch(field, formValue) {
      return (
        formValue[field.name] && field.value === this.formValue[field.name]
      );
    },
    checkSetValue(value) {
      const { setFieldValue = [] } = this.field;

      if (!setFieldValue.length) return;
      for (const field of Object.values(setFieldValue)) {
        const { dependsOnOwnValue, dependsOnOwnValueNotSet, name } = field;

        if (
          dependsOnOwnValue?.values &&
          dependsOnOwnValue.values.includes(value)
        ) {
          this.formValue[name] = dependsOnOwnValue.setValue;

          continue;
        }

        if (
          dependsOnOwnValueNotSet?.values &&
          !dependsOnOwnValueNotSet.values.includes(value)
        ) {
          this.formValue[name] = dependsOnOwnValueNotSet.setValue;
        }
      }
    }
  }
};

export const input = {
  props: {
    fieldWrapper: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      returnType: "",
      showVariables: false,
      isMounted: false,
      lastValueSet: ""
    };
  },
  computed: {
    // Get return type icon
    returnTypeIcon: function () {
      return returnTypeIcons[this.returnType];
    },
    // Get return type text based on current return type
    returnTypeText: function () {
      if (!this.returnType) {
        return "";
      }
      let prefix = this.$t("formHelper.returnType"),
        typeText = this.$t("formHelper.returnTypes." + this.returnType);
      return `${prefix}: <span class="font-italic">${typeText}</span>`;
    },
    // Get filter prop for variables dropdown as string
    filter: function () {
      let value = this.$v.value.$model;
      if (value === null) {
        value = "";
      }
      return String(value);
    },
    highlightType: function () {
      // Highlight all as default
      return "all";
    },
    showPlaceholder: function () {
      return this.computedValue === "";
    },
    enableTypecast: function () {
      // Return if typecasting should be enabled
      return this.field.enableTypecast ?? this.options.enableTypecast ?? false;
    }
  },
  methods: {
    setReturnType(type) {
      this.returnType = type;
    },
    setVariable(variable) {
      this.setReturnType("variable");
      this.computedValue = variable;
      this.highlightInput(variable);
      this.showVariables = false;
    },
    onContentEditableInput() {
      // Save current cursor position
      this.lastCursorPos = saveCursorPosition(this.$refs.input);
      // Get value from contenteditable
      let value = this.$refs.input.innerText.replace(/(\r\n|\n|\r)/gm, "");
      value = this.checkValue(value);
      // Highlight and set value
      this.highlightInput(value);
      this.computedValue = value;
      // Restore the cursor position
      restoreCursorPosition(this.$refs.input, this.lastCursorPos);
    },
    checkValue(value) {
      // Can be replaced by component to insert custom (validation) logic
      return value;
    },
    highlightInput(value) {
      // If typecast is disabled, html input is used
      if (!this.enableTypecast) {
        return;
      }
      value = String(value);
      // Set new html value
      this.$refs.input.innerHTML = highlight(
        value,
        this.enableVariables,
        this.returnType,
        this.highlightType
      );
    }
  }
};

export const select = {
  inject: {
    parent: {
      default: {}
    }
  },
  created() {
    if (this?.parent?.type === "group") return;

    const { async } = this.field?.optionsConfig || {};

    if (
      !this.value ||
      !async ||
      !!this.field?.options.length // Don't load the option, if we already have options
    )
      return;

    // Get the Option By Value
    const payload = this.value?.length ? { value: this.value } : {};
    this.loadDataOptions(payload);
  },
  data() {
    return {
      fieldRefreshKey: 0,
      isLoadingDataOptions: false,
      searchInputValue: "",
      keyCodeBlacklist: {
        // Arrows
        leftArrow: 37,
        upArrow: 38,
        rightArrow: 39,
        downArrow: 40,

        // Modifier keys
        shift: 16,
        ctrl: 17,
        alt: 18,
        leftCommand: 91,
        rightCommand: 92,

        // Function keys
        f1: 112,
        f2: 113,
        f3: 114,
        f4: 115,
        f5: 116,
        f6: 117,
        f7: 118,
        f8: 119,
        f9: 120,
        f10: 121,
        f11: 122,
        f12: 123,

        // Enter/Return
        enter: 13,

        // Escape
        escape: 27,

        // Space
        space: 32,

        // Delete/Backspace
        delete: 46,
        backspace: 8,

        // Tab
        tab: 9,

        // Caps Lock
        capsLock: 20,

        // Home/End
        home: 36,
        end: 35
      }
    };
  },
  watch: {
    field: {
      deep: true,
      handler: function () {
        this.fieldRefreshKey++;
      }
    }
  },
  computed: {
    isLoading: function () {
      // We need to "call" this variable, otherwise vue doesn't notice any changes to this.field
      this.fieldRefreshKey++;
      return this.field.loading;
    },
    items: function () {
      // If no options set, return empty array
      if (!this.field.options) {
        return [];
      }
      // Map through given options
      let optionsToMap =
        typeof this.field.options === "object"
          ? Object.values(this.field.options)
          : this.field.options;
      let options = optionsToMap.map(o => {
        // If option is of type string, set value also as label
        if (typeof o !== "object") {
          o = { value: o, label: o, group: null, info: "" };
        }
        // Set label with name and value as fallbacks
        let label = this.field?.optionsConfig
          ? o.label
          : this.getSnippet(o.label ?? o.name ?? o.value);

        // Return new item object
        let option = {
          value: o.value,
          label: label,
          group: o.group ? this.getSnippet(o.group) : null,
          info: this.getSnippet(o.info)
        };

        if (o.children) {
          option["children"] = o.children;
        }

        return option;
      });
      // Sort options if necessary
      // Either inside given group else for all options
      let optionsSorted;
      if (this.field.sort === "desc" || this.field.sort === -1) {
        // Sorting options descending
        optionsSorted = options.sort((a, b) => {
          if (a.group && b.group) {
            return b.group.localeCompare(a.group);
          }
          return b.label.localeCompare(a.label);
        });
      } else if (this.field.sort === "asc" || this.field.sort === 1) {
        // Sorting options ascending
        optionsSorted = options.sort((a, b) => {
          if (a.group && b.group) {
            return a.group.localeCompare(b.group);
          }
          return a.label.localeCompare(b.label);
        });
      } else {
        // Sorting options ascending
        optionsSorted = options.sort((a, b) => {
          if (a.group && b.group) {
            return a.group.localeCompare(b.group);
          }
          return 0;
        });
      }

      let newOptions = [];
      let lastGroup = null;
      // Sort options by group if set
      optionsSorted.forEach(option => {
        // Add header attribute for grouped view
        if (option.group !== null && lastGroup !== option.group) {
          newOptions.push({
            header: option.group
          });
          lastGroup = option.group;
        }
        // Add to options array
        newOptions.push(option);
      });
      // Return select options
      return newOptions;
    },
    searchInput: {
      get() {
        return this.searchInputValue;
      },
      set(value = "") {
        if (!value || this.value === value) return;
        this.searchInputValue = value.toString();
      }
    }
  },
  methods: {
    // Get text as snippet by given prefix
    getSnippet(text) {
      // Get prefix
      let prefix = this.options.snippetPrefix;
      // Get snippet
      let snippet = getSnippet(text, prefix);
      // If no snippet is found, try to get a nested option snippet
      if (snippet === text) {
        // Add nested option prefix by field name + "Options"
        prefix = `${prefix}.${this.field.name}Options`;
        snippet = getSnippet(text, prefix);
      }
      return snippet;
    },
    onOpen() {
      const { async, eager, onOpen = false } = this.field?.optionsConfig || {};

      if (!async || (!eager && !onOpen)) return;

      this.loadDataOptions();
    },
    loadDataOptions(params) {
      this.isLoadingDataOptions = true;

      const payload = {
        optionsClass: this.field?.optionsClass,
        optionsMethod: this.field?.optionsMethod,
        ...params
      };

      getDataOptions(this.field, payload).finally(
        () => (this.isLoadingDataOptions = false)
      );
    },
    executeSearch: _.debounce(function () {
      const payload = {
        search: this.searchInput
      };

      this.loadDataOptions(payload);
    }, 500),
    search(event) {
      const { async, eager } = this.field?.optionsConfig || {};

      if (
        !async ||
        !this.searchInput?.length ||
        eager || // If the options have already been eager loaded on open, there's no need to perform a search.
        Object.values(this.keyCodeBlacklist).includes(event.keyCode)
      )
        return;
      this.isLoadingDataOptions = true;
      this.executeSearch();
    },
    reloadDataOptions() {
      const payload = {
        optionsClass: this.field?.optionsClass,
        optionsMethod: this.field?.optionsMethod
      };
      DataOptions.clearCache(payload);

      this.loadDataOptions();
    }
  }
};

export const variablesField = {
  data() {
    return {
      // Shows if variables component is active
      isVariablesField: false
    };
  },
  computed: {
    allowVariablesField: function () {
      // Return if variables component is allowed for this field
      return (
        this.options.enableVariables &&
        this.field.enableVariables !== false &&
        this.variablesField.includes(this.field.type)
      );
    },
    variablesFieldActive: function () {
      // Get status to show variables component or not
      if (!this.allowVariablesField) {
        return false;
      }
      return this.isVariablesField;
    }
  },
  mounted() {
    this.setVariablesField();
  },
  methods: {
    // Validate value and return result
    validate() {
      // If variables field is active but has no value, switch back to normal field
      if (this.isVariablesField && !this.value) {
        this.toggleVariablesField();
      }
      // Trigger vuelidate validation
      this.$v.$touch();
      // Return if vuelidate has errors
      return this.isVariablesField ? !!this.value : !this.$v.$anyError;
    },
    setVariablesField() {
      // Check if value is a variable
      this.isVariablesField = customRegex.variable.test(this.value);
    },
    toggleVariablesField() {
      let status = !this.isVariablesField;
      this.isVariablesField = status;
      // Set value to either empty string (if variables component gets activated)
      // or field's default value (if variables component gets deactivated)
      this.value = status ? "" : fieldDefaults[this.field.type];
    }
  }
};
