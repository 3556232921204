<template>
  <div v-if="isMounted" class="form-helper" :class="{ factory: factory }">
    <!------------ START: Title ------------>
    <div v-if="config.title" class="row align-items-center">
      <div class="h5 mb-0 col-12 font-weight-bolder">
        {{ config.title }}
      </div>
    </div>
    <!------------ END: Title ------------>
    <!------------ START: Form fields rendering ------------>
    <div class="row row--dense">
      <div
        v-for="formField in computedForm"
        :key="factory ? formField.id : formField.name"
        class="form-field py-1"
        :class="[
          { 'col-12': formField.type !== 'hidden' && !formField.colClass },
          formField.colClass,
          formField.class,
          { 'py-1': options.labelStacked },
          { active: factory && activeFieldId === formField.id },
          { 'd-flex align-items-center justify-content-left rounded': factory }
        ]"
      >
        <FormField
          :ref="'field-' + formField.name"
          v-model="computedValues"
          :component="formElements[formField.type]"
          :field="formField"
          class="flex-grow-1"
          @action="onAction"
          @change="onChange"
          @load-formatter="$emit('load-formatter', $event)"
        />
        <div v-if="factory">
          <div class="btn text-hover-primary" @click="e => onClick(formField)">
            <i
              class="fal p-0"
              :class="[
                activeFieldId === formField.id ? 'fa-pen-slash' : 'fa-pen'
              ]"
            />
          </div>
        </div>
      </div>
    </div>
    <!------------ END: Form fields rendering ------------>
    <!------------ START: Variable value tooltip ------------>
    <b-tooltip
      v-if="target"
      :show="true"
      :target="target"
      noninteractive
      placement="top"
      custom-class="form-helper-tooltip"
      boundary="document"
    >
      <div class="font-weight-bold">{{ $t("formHelper.value") }}</div>
      {{ variableValue ? variableValue : $t("formHelper.noValueFound") }}
    </b-tooltip>
    <!------------ END: Variable value tooltip ------------>
  </div>
</template>

<script>
import { LOAD_CONFIG_VALUES } from "@/core/services/store/variables_v1.module";
import { mapActions, mapGetters } from "vuex";
import {
  fieldDefaults,
  skipFields
} from "@/components/Tools/FormHelper/Helper/constants";
import _ from "lodash";
import {
  checkDependencies,
  customVariablesNormalized,
  nestedValue
} from "@/components/Tools/FormHelper/Helper/functions";
import FormField from "@/components/Tools/FormHelper/Components/FormField";
import { computed } from "vue";

export const optionsConfiguration = {
  title: {
    type: "text",
    required: true,
    default: "Form Title"
  },
  snippetPrefix: {
    type: "text",
    required: true,
    default: ""
  },
  labelStacked: {
    type: "checkbox",
    default: false
  },
  showLabels: {
    type: "checkbox",
    default: true
  },
  enableVariables: {
    type: "checkbox",
    default: false
  },
  configValues: {
    type: "checkbox",
    default: true
  },
  customVariables: {
    type: "array",
    default: []
  },
  filterHiddenValues: {
    type: "checkbox",
    default: false
  },
  fullWidth: {
    type: "checkbox",
    default: false
  },
  distinctVariables: {
    type: "checkbox",
    default: false
  },
  disableSkeletonLoading: {
    type: "checkbox",
    default: false
  }
};
const optionsTemplate = {
  title: "",
  snippetPrefix: "",
  labelStacked: false,
  showLabels: true,
  enableVariables: false,
  configValues: true,
  customVariables: [],
  filterHiddenValues: false,
  fullWidth: false,
  distinctVariables: false,
  enableTypecast: false,
  disableSkeletonLoading: false
};

export default {
  name: "FormHelper",
  components: { FormField },
  inject: {
    parent: {
      default: {}
    }
  },
  provide: function () {
    return {
      options: this.options,
      variablesField: this.variablesField,
      formFields: this.computedForm,
      formValue: computed(() => this.value)
    };
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    form: {
      type: Array,
      default: () => []
    },
    customFormValues: {
      type: Object,
      default: undefined
    },
    config: {
      type: Object,
      default: () => optionsTemplate
    },
    factory: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isMounted: false,
      target: null,
      options: {},
      formElements: {
        action: () => import("@/components/Tools/FormHelper/Fields/Action"),
        alert: () => import("@/components/Tools/FormHelper/Fields/Alert"),
        array: () => import("@/components/Tools/FormHelper/Fields/Array"),
        checkbox: () => import("@/components/Tools/FormHelper/Fields/Checkbox"),
        code: () => import("@/components/Tools/FormHelper/Fields/Code"),
        condition: () =>
          import("@/components/Tools/FormHelper/Fields/Condition"),
        date: () => import("@/components/Tools/FormHelper/Fields/Date"),
        datetime: () => import("@/components/Tools/FormHelper/Fields/DateTime"),
        description: () =>
          import("@/components/Tools/FormHelper/Fields/Description"),
        file: () => import("@/components/Tools/FormHelper/Fields/File"),
        filter: () => import("@/components/Tools/FormHelper/Fields/Filter"),
        float: () => import("@/components/Tools/FormHelper/Fields/Float"),
        group: () => import("@/components/Tools/FormHelper/Fields/Group"),
        headline: () =>
          import("@/components/Tools/FormHelper/Fields/Headline.vue"),
        hidden: () => import("@/components/Tools/FormHelper/Fields/Hidden"),
        image: () => import("@/components/Tools/FormHelper/Fields/Image"),
        json: () => import("@/components/Tools/FormHelper/Fields/Json"),
        label: () => import("@/components/Tools/FormHelper/Fields/Label"),
        multiselect: () =>
          import("@/components/Tools/FormHelper/Fields/MultiSelect"),
        number: () => import("@/components/Tools/FormHelper/Fields/Number"),
        password: () => import("@/components/Tools/FormHelper/Fields/Password"),
        range: () => import("@/components/Tools/FormHelper/Fields/Range"),
        select: () => import("@/components/Tools/FormHelper/Fields/Select"),
        text: () => import("@/components/Tools/FormHelper/Fields/Text"),
        textarea: () => import("@/components/Tools/FormHelper/Fields/Textarea"),
        texteditor: () =>
          import("@/components/Tools/FormHelper/Fields/TextEditor"),
        time: () => import("@/components/Tools/FormHelper/Fields/Time")
      },
      invalid: false,
      variablesField: [
        "checkbox",
        "date",
        "datetime",
        "json",
        "multiselect",
        "password",
        "range",
        "select",
        "time",
        "code"
      ]
    };
  },
  computed: {
    ...mapGetters("variables", ["configValues"]),
    ...mapGetters("formHelperFactory", ["activeFieldId"]),
    values: function () {
      // Return copy of value object
      return Object.assign({}, this.value);
    },
    filteredValues: function () {
      let values = Object.assign({}, this.values);
      if (this.options.filterHiddenValues) {
        values = this.filterHiddenValues(values);
      }
      // Return all values for form field to get it's nested value
      return values;
    },
    computedValues: {
      get: function () {
        // Return all values for form field to get it's nested value
        return this.values;
      },
      set: function (payload) {
        // Get current value
        let values = this.filteredValues;
        // Payload contains name of field and new value
        // Get path to update
        let path = nestedValue(values, payload.name, true);
        // Update nested value
        this.$set(path, payload.name.split(".").pop(), payload.value);
        // Update v-model
        this.$emit("input", values);
      }
    },
    computedForm: function () {
      return this.form?.filter(field => {
        return checkDependencies(field, this.values, this.customFormValues);
      });
    },
    variableValue: function () {
      if (!this.target) {
        return "";
      }
      let inputValue = this.target.innerText || this.target.value || "";
      let variable = inputValue.replace("{{", "").replace("}}", "").trim();
      let keys = variable.split(".");
      let setName = keys.shift();
      let set = this.variablesSets.find(set => set.prefix === setName);
      if (!set) {
        return "";
      }
      let firstKey = keys.shift();
      let value = set.variables.find(
        variable => variable[set.text] === firstKey
      )?.[set.value];
      if (value === undefined) {
        return "";
      }
      while (keys.length) {
        if (typeof value !== "object") {
          break;
        }
        value = value[keys.shift()];
      }
      return value;
    },
    variablesSets: function () {
      let sets = customVariablesNormalized(this.options.customVariables);
      if (this.options.configValues) {
        sets.unshift({
          name: "configValues",
          prefix: "config",
          text: "name",
          value: "value",
          variables: this.configValues
        });
      }
      return sets;
    }
  },
  watch: {
    computedForm: function (newForm) {
      if (!newForm.length) {
        this.$emit("is-empty");

        return;
      }

      this.$emit("not-empty");
    }
  },
  created() {
    window.addEventListener("mousemove", this.onMouseMove);
  },
  mounted() {
    this.mergeOptions();
    this.handleVariables();
    this.setDefaultValues();
    this.checkFilteredValues();
    this.isMounted = true;
  },
  beforeDestroy() {
    window.removeEventListener("mousemove", this.onMouseMove);
  },
  methods: {
    ...mapActions("variables", [LOAD_CONFIG_VALUES]),
    validate() {
      // Validate every visible field
      let falseFields = [];
      this.computedForm.forEach(formField => {
        // Get ref name to access component
        let name = "field-" + formField.name;
        // If child validation returns false
        if (this.$refs[name] === undefined || this.$refs[name] === null) {
          return;
        }
        if (!this.$refs[name][0].validate()) {
          // Add field to false fields
          falseFields.push({
            field: formField,
            value: nestedValue(this.values, formField.name)
          });
        }
      });
      // Return valid state
      return falseFields.length ? falseFields : true;
    },
    mergeOptions() {
      // Merge given config with default options
      Object.assign(this.options, optionsTemplate, this.config);
    },
    handleVariables() {
      // Check if variables are allowed globally or for specific fields
      if (
        !this.options.enableVariables &&
        !this.form.find(field => field.enableVariables === true)
      ) {
        return;
      }
      // If config values are enabled, load them into store
      if (this.options.configValues) {
        this[LOAD_CONFIG_VALUES]();
      }
    },
    setDefaultValues() {
      // Loop through each form field
      this.form.forEach(formField => {
        // Check if field has all necessary properties
        let valid = this.checkProperties(formField);
        // Return if check fails or no name is set
        if (!valid || !formField.name || skipFields.includes(formField.type))
          return;
        // Set value and path as values

        let value = nestedValue(this.values, formField.name),
          path = nestedValue(this.values, formField.name, true);
        // If values object does not have name as prop or value is same as fallback value
        if (
          value === undefined ||
          (_.isEqual(
            value,
            formField.default ?? fieldDefaults[formField.type]
          ) &&
            !(formField.type === "condition" && typeof value === "string"))
        ) {
          // Set value as either given default or field fallback
          this.$set(
            path,
            formField.name.split(".").pop(),
            formField.default ?? fieldDefaults[formField.type]
          );
        }
      });

      this.$emit("input", this.values);
    },
    checkFilteredValues() {
      // Check if values of hidden fields are set
      // Only works if filterHiddenValues option is set
      if (
        this.options.filterHiddenValues &&
        !_.isEqual(this.values, this.filteredValues)
      ) {
        this.$emit("input", this.filteredValues);
      }
    },
    filterHiddenValues(unfilteredValues) {
      let values = {};
      this.computedForm
        .filter(f => !skipFields.includes(f.type))
        .forEach(formField => {
          // Get value from given v-model object by field name
          let value = _.get(
            unfilteredValues,
            formField.name,
            fieldDefaults[formField.type]
          );
          // Get default value to set
          if (
            value === undefined ||
            _.isEqual(value, fieldDefaults[formField.type])
          ) {
            value = formField.default ?? fieldDefaults[formField.type];
          }
          // Set value in new value object by field name
          _.set(values, formField.name, value);
        });
      return values;
    },
    // Check if form field contains all required properties
    checkProperties(field) {
      let invalid = false;
      /********* Required properties: ["type", "name"] *********/
      // Check if type prop is set
      if (!field.type) {
        invalid = true;
      }
      // Check if field type exists
      if (!this.formElements[field.type]) {
        invalid = true;
      }
      // Skip fields action, alert, headline, label
      if (skipFields.includes(field.type)) {
        if (invalid) this.invalid = true;
        return !invalid;
      }
      // Check if name prop is set
      if (!field.name) {
        invalid = true;
      }
      // Check if field name is set and unique
      if (
        field.name !== undefined &&
        this.form.filter(f => f.name === field.name).length > 1
      ) {
        invalid = true;
      }
      if (invalid) this.invalid = true;
      return !invalid;
    },
    onMouseMove(e) {
      if (
        typeof e?.target?.className === "string" &&
        e?.target?.className?.includes("highlight-variable")
      ) {
        this.target = e.target;
      } else {
        this.target = null;
      }
    },
    onAction(payload) {
      this.$emit("action", payload);
    },
    onChange(payload) {
      this.$emit("change", payload);
    },
    onClick(formField) {
      if (!this.factory) {
        return;
      }
      this.$emit("click", formField);
    }
  }
};
</script>

<style lang="scss">
// Make yedi scss variables accessible
@import "~@/assets/sass/components/_variables.yedi.scss";
.form-helper {
  .highlight-variable {
    // yedi Lachs
    color: var(--primary);
    &:hover {
      background: #a8d1ce;
    }
  }
  .highlight-text {
    //color: #78cc9b;
  }
  .highlight-number {
    color: #f28d49;
  }
  .highlight-bool-null {
    color: #aaa7dc;
    font-style: italic;
  }
  .highlight-array {
    color: var(--primary);
  }
  i.toggle-empty {
    position: absolute;
    top: 85%;
    font-size: 10px !important;
    transform: translateX(125%);
  }
  .disabled,
  [disabled] {
    background-color: #f3f6f9;
  }
  .contenteditable-content {
    max-width: 100%;
    height: 100%;
    overflow-x: auto;
    white-space: nowrap;
    // Hide scrollbar for Chrome, Safari and Opera
    &::-webkit-scrollbar {
      display: none;
    }
    // Hide scrollbar for IE, Edge and Firefox
    -ms-overflow-style: none;
    scrollbar-width: none;
    &:before {
      content: "";
    }
  }
}
// Tooltips render as last element of body
.tooltip {
  &.form-helper-tooltip {
    .tooltip-inner {
      max-width: none;
    }
    &.form-helper-variables-tooltip {
      .tooltip-inner {
        max-width: 250px;
      }
    }
  }
}
// Fake a placeholder
[contenteditable] {
  overflow-x: hidden;
  overflow-y: hidden;
  white-space: nowrap;
  &:not(:focus).empty:before {
    content: attr(data-placeholder);
    color: $text-muted;
    cursor: text;
    pointer-events: none;
  }
}
</style>
